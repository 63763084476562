import { useSession } from "next-auth/react";
import React, { Fragment, useEffect, useState } from "react";
import eventClientApi from "@/api-client/event-client";
import Avatar from "@/components/ui/avatar";
import { Loader } from "@/components/ui/loader";
import { Text } from "@/components/ui/text";
import { Add, Edit } from "iconsax-react";

import { usePaticipantsInvite } from "@/stores/use-guest-invite-store";
import Multiline from "@/components/ui/multiline";
// import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs-variant1.";
import NoDataView from "@/components/ui/no-data-view";
import { Check } from "lucide-react";
import { motion } from "framer-motion";
import CreateGroup from "./create";
import AddMember from "./add-member";
import GroupGuests from "./group-users";

const tabs = [
  {
    key: "email",
    title: "Email"
  }
  // {
  //   key: "pastEvents",
  //   title: "Past Events"
  // }
];

const Group = ({ emails, setEmails, role }: any) => {
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [addMember, setAddMember] = useState<boolean>(false);
  const [opened, setOpened] = useState<string>("");
  const { data: session }: any = useSession();
  const [results, setResults] = useState([]);
  const [addGroupLoading, setAddGroupLoading] = useState<{ id: string; loading: boolean }>({
    id: "",
    loading: true
  });
  const [selectedGroup, setSelectedGroup] = useState<any>({
    name: "",
    description: "",
    image: ""
  });

  const { setShowPaticipantsBar } = usePaticipantsInvite();

  const getAllGroups = async () => {
    try {
      setOpened("");
      const { data } = await eventClientApi
        .get(`api/v2/guestlist/group`, {
          searchParams: {
            user_id: session?.user?.id
          }
        })
        .json<any>();

      setResults(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllGroups();
  }, []);

  const handleCreate = () => {
    setCreateMode(true);
    setShowPaticipantsBar(false);
    setSelectedGroup({});
  };

  const handleEdit = (group: any) => {
    setSelectedGroup(group);
    setAddMember(true);
    setShowPaticipantsBar(false);
  };

  const handleAddGuest = async (id: string, toggle?: boolean, bulkAction = false) => {
    // setLoading(true);
    if (!toggle) {
      if (bulkAction) {
        setUsers((prevState: any) =>
          prevState.map((state: any) => ({ ...state, selected: false }))
        );
        setEmails([]);
      } else {
        setUsers((prevState: any) =>
          prevState.map((state: any) => ({
            ...state,
            selected: state.groupId === id ? false : state.selected
          }))
        );
        setEmails((prevState: any) => prevState.filter((email: any) => email.groupId !== id));
        setAddGroupLoading({ id: "", loading: false });
      }
    } else {
      try {
        const { data } = await eventClientApi
          .get(`api/v2/guestlist/all/${id}`, {
            searchParams: { user_id: session?.user?.id }
          })
          .json<any>();

        const _data = data.map((d: any) => ({ ...d, selected: true }));
        const invites = _data.map((d: any) => ({
          email: d.email,
          role: role,
          selected: true,
          groupId: id
        }));

        setEmails((prevState: any) => [...prevState, ...invites]);
        setUsers((prevState: any) => prevState.map((state: any) => ({ ...state, selected: true })));
      } catch (error) {
        console.log(error);
      } finally {
        setAddGroupLoading({ id: "", loading: false });
        // setLoading(false);
      }
    }
  };

  const handleSelectAll = async (e: boolean) => {
    if (e) {
      const _data = users.map((d: any) => ({ ...d, selected: true }));
      setUsers(_data);
      setEmails((prevState: any) => {
        const filteredPrevState = prevState.filter((email: any) => email.groupId !== opened);
        // Add new emails
        return [
          ...filteredPrevState,
          ..._data.map((d: any) => ({
            ...d,
            selected: true,
            groupId: opened
          }))
        ];
      });
    } else {
      setUsers((prevState: any) => prevState.map((state: any) => ({ ...state, selected: false })));
      setEmails((prevState: any) => prevState.filter((email: any) => email.groupId !== opened));
    }
  };

  const handleFetchGroupGuest = async (id: string, select?: boolean) => {
    try {
      const { data } = await eventClientApi
        .get(`api/v2/guestlist/all/${id}`, {
          searchParams: {
            user_id: session?.user?.id
          }
        })
        .json<any>();
      const _data = data?.map((d: any) => ({ ...d, selected: false }));
      setUsers(_data);
      setOpened(id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleEmailSelection = (user: any) => {
    setEmails((prevEmails: any) => {
      const isAlreadySelected = prevEmails.some((e: any) => e.email === user.email);
      if (isAlreadySelected) {
        return prevEmails.filter((e: any) => e.email !== user.email);
      } else {
        return [...prevEmails, { email: user?.email, groupId: opened, role, selected: true }];
      }
    });
  };

  const slideAnimation = {
    hidden: { x: "100%" }, //
    visible: { x: 0 },
    exit: { x: "100%" }
  };

  const countUsersWithGroupId = emails.filter(
    ({ groupId }: { groupId: string }) => groupId !== undefined
  ).length;

  return (
    <div>
      {createMode && (
        <motion.div
          className="bg-white p-l z-20 h-full w-full absolute left-0 right-0 bottom-0 top-0"
          variants={slideAnimation}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ type: "tween", duration: 0.05 }}
        >
          <CreateGroup
            setCreateMode={setCreateMode}
            selectedGroup={selectedGroup}
            setShowPaticipantsBar={setShowPaticipantsBar}
            getAllGroups={getAllGroups}
          />
        </motion.div>
      )}

      {addMember && (
        <motion.div
          className="bg-white p-ml z-20 h-full w-full absolute left-0 right-0 bottom-0 top-0"
          variants={slideAnimation}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ type: "tween", duration: 0.05 }}
        >
          <AddMember
            selectedGroup={selectedGroup}
            setAddMember={setAddMember}
            getAllGroups={getAllGroups}
            setShowPaticipantsBar={setShowPaticipantsBar}
          />
        </motion.div>
      )}

      {opened ? (
        <motion.div
          className="bg-white p-ml z-20 h-full w-full absolute left-0 right-0 bottom-0 top-0"
          variants={slideAnimation}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ type: "tween", duration: 0.05 }}
        >
          <GroupGuests
            selectedGroup={selectedGroup}
            setOpened={setOpened}
            users={users}
            handleToggleEmailSelection={handleToggleEmailSelection}
            emails={emails}
            handleSelectAll={handleSelectAll}
          />
        </motion.div>
      ) : null}

      <div className="relative h-[90%]">
        <div className="">
          {!createMode && !addMember ? (
            <Fragment>
              <div>
                <div className="w-full">
                  <div className="py-m px-l  bg-zinc-100 flex items-center justify-between">
                    <div>
                      <Text variant={"subtitle_one"} className="font-semibold">
                        {`Selected ${countUsersWithGroupId} guests`}
                      </Text>
                    </div>
                    <div className="flex items-center gap-s mr-xs">
                      <Text
                        variant={"body_two_strong"}
                        className="cursor-pointer flex items-center gap-xxs"
                        onClick={handleCreate}
                      >
                        <Add /> Create Group
                      </Text>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="h-full overflow-y-auto mt-l">
                    {loading ? (
                      <div className="mx-auto">
                        <Loader />
                      </div>
                    ) : results?.length ? (
                      results?.map((group: any, index: number) => {
                        return (
                          <Fragment>
                            <div
                              className="cursor-pointer"
                              onClick={(e) => {
                                setSelectedGroup(group);
                                handleFetchGroupGuest(group.id);
                              }}
                            >
                              <Multiline
                                className="bg-transparent px-0 !mx-0"
                                icon={
                                  <div
                                    className="relative flex gap-m items-center cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setAddGroupLoading({ id: group.id, loading: true });
                                      const isSelected = !!emails?.find(
                                        (e: any) => e.groupId === group?.id
                                      )?.groupId;
                                      handleAddGuest(group?.id, !isSelected);
                                    }}
                                  >
                                    <Fragment>
                                      <Avatar
                                        imgSrc={group?.image}
                                        size={56}
                                        activeRing={false}
                                        variant="image"
                                        present={false}
                                        presence={false}
                                        presenceState="blocked"
                                      />
                                      {addGroupLoading.loading &&
                                      addGroupLoading.id === group.id ? (
                                        <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center bg-black opacity-75 rounded-full p-1">
                                          <Loader size="small" />
                                        </div>
                                      ) : (
                                        !!emails?.find((e: any) => e.groupId === group?.id)
                                          ?.groupId && (
                                          <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center bg-black opacity-75 rounded-full p-1">
                                            <Check size={16} className="text-white" />
                                          </div>
                                        )
                                      )}
                                    </Fragment>
                                  </div>
                                }
                                titleText={group?.name}
                                text1={`${group?.numberOfGuests} People`}
                                titleTextSize={"medium"}
                                text1Size={"small"}
                                action={
                                  <div className="flex justify-center items-center cursor-pointer gap-m">
                                    <Edit
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit(group);
                                      }}
                                      size={14}
                                    />
                                  </div>
                                }
                              />
                            </div>

                            <div className="border-b border-gray-200 w-full"></div>
                          </Fragment>
                        );
                      })
                    ) : (
                      <div className="content-center h-full mx-auto text-center">
                        <NoDataView title={`No Groups found.`} imageKey={"guest"} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Group;
