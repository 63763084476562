import React, { useCallback, useEffect, useState } from "react";
import eventClientApi from "@/api-client/event-client";
import { Tooltip } from "@/components/custom/tooltip";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Text } from "@/components/ui/text";
import { useModal } from "@/stores/use-modal-store";
import { redirectToSignIn } from "@/lib/utils";
import { IconInfoCircle } from "@tabler/icons-react";
import { useSession } from "next-auth/react";
import { useParams, useRouter } from "next/navigation";
import { toast } from "sonner";
import { Loader } from "@/components/ui/loader";

const EventPasscodeModal = () => {
  const { isOpen, type, data, onClose } = useModal();
  const [passcode, setPasscode] = useState<string>("");
  const [requestAccessApplied, setRequestAccessApplied] = useState<boolean>(false);
  const [requestAccessLoader, setRequestAccessLoader] = useState<boolean>(false);

  const router = useRouter();

  const params = useParams();

  const isModalOpen = isOpen && type === "eventPasscode";

  const { data: user } = useSession();

  const { callback } = data;

  const getRequestAccessStatus = async () => {
    try {
      if (!data?.eventData?.event_id) return;
      const response = await eventClientApi
        .get(`api/v2/users/${user?.user?.id}/request-access?eventId=${data?.eventData?.event_id}`)
        .json<any>();
      if (response?.data?.status === "success") {
        setRequestAccessApplied(false);
      }
    } catch (error: any) {
      if (error.response.status === 403) {
        setRequestAccessApplied(true);
      }
    } finally {
    }
  };

  const handleRequestAccess = useCallback(async () => {
    try {
      setRequestAccessLoader(true);
      const response = await eventClientApi
        .post(`api/v2/users/${user?.user?.id}/request-access`, {
          json: {
            ...data?.eventData,
            user_name: `${user?.user?.firstName} ${user?.user?.lastName}`,
            user_email: user?.user?.email
          }
        })
        .json<any>();
      if (response?.status === "success") {
        toast.success(response?.message);
        getRequestAccessStatus();
      }
    } catch (error: any) {
      const { status } = error?.response || {};
      if (status === 401) {
        redirectToSignIn({ callbackUrl: `/${params?.alias}` });
      } else {
        toast.error(error.message);
      }
    } finally {
      setRequestAccessLoader(false);
    }
  }, [data?.eventData]);

  useEffect(() => {
    if (data?.eventData?.event_id) getRequestAccessStatus();
  }, [data?.eventData?.event_id]);

  const handleClose = () => {
    try {
      window.close();
      setTimeout(() => {
        if (!window.closed) {
          onClose();
          router.push(`/events`);
        }
      }, 300);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      callback?.(passcode);
    }
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[500px]" isShowCloseBtn={true}>
        <DialogDescription>
          <Text variant={"subtitle_one"}>Require Password</Text>
        </DialogDescription>
        <Text variant={"body_one"}>
          To access the event details and participate, please enter the provided password below:
        </Text>

        <Input
          variant="medium-s"
          type="text"
          className="rounded-medium mt-s w-full px-xl"
          inputClassName="w-full"
          placeholder="Enter Passcode"
          value={passcode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
          onKeyDown={handleSubmit}
        />

        <div className="flex justify-center -z-50 mt-m items-center">
          <Button
            onClick={() => callback?.(passcode)}
            disabled={!passcode?.length}
            className="w-full"
          >
            Confirm
          </Button>
        </div>

        <div className="flex  mx-auto items-center">
          <Text variant={"caption_one"}>Don't have the password? Click here to</Text>

          <Button
            variant={"text"}
            className="text-brand-500 cursor-pointer text-sm px-s bg-transparent"
            onClick={handleRequestAccess}
            disabled={requestAccessApplied || requestAccessLoader}
          >
            Request Access
          </Button>

          {requestAccessLoader ? (
            <Loader size="xs" />
          ) : (
            <Tooltip
              title={
                requestAccessApplied
                  ? "You have already applied for request access for this event"
                  : "User can only request access once for an event."
              }
            >
              <IconInfoCircle size={17} />
            </Tooltip>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EventPasscodeModal;
