"use client";
import { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { clientDeviceIdKey } from "@key.ai/constants";
import { env } from "@/env";
import { detectIncognito } from "detectincognitojs";

type FingerprintContextType = {
  fingerprint: string | null;
};

const FingerprintContext = createContext<FingerprintContextType>({
  fingerprint: null
});

export const useFingerprint = () => {
  return useContext(FingerprintContext);
};

export const FingerprintProvider = ({ children }: { children: React.ReactNode }) => {
  const [fingerprint, setFingerprint] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        // GENERATE UNIQUE BROWSER DEVICEID
        const { ClientJS } = await import("clientjs");
        const client = new ClientJS();
        const fingerprint = client.getCustomFingerprint(
          client.getUserAgentLowerCase(),
          client.getPlugins(),
          client.getLanguage(),
          client.getBrowser()
        );
        let isPrivate = false;
        try {
          isPrivate = (await detectIncognito()).isPrivate;
        } catch (error) {}

        const deviceId = `${env.NEXT_PUBLIC_ENV}_${fingerprint}_${isPrivate ? "1" : "0"}`;
        const options: Cookies.CookieAttributes = {
          sameSite: "None",
          secure: window.location.protocol === "https:",
          expires: 7
        };
        Cookies.set(clientDeviceIdKey, deviceId, options);
        setFingerprint(deviceId);
      } catch (error) {
        //
      }
    })();
  }, []);

  return (
    <FingerprintContext.Provider value={{ fingerprint }}>{children}</FingerprintContext.Provider>
  );
};
