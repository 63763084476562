"use client";

import SectionHeader from "@/components/ui/section-header";
import { Text } from "@/components/ui/text";
import {
  Add,
  ArrowDown2,
  ArrowLeft2,
  ArrowUp2,
  CloseCircle,
  SearchNormal1,
  Send2,
  Ticket,
  Trash
} from "iconsax-react";
import { useParams } from "next/navigation";
import { Fragment, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useModal } from "@/stores/use-modal-store";
import { Dialog, DialogContent, DialogFooter } from "@/components/ui/dialog";
import EmailInvite from "@/components/app/events/manage/email-invite";
import Box from "@/components/ui/box";
import { CollapsibleComponent } from "@/components/ui/collapse";
import Multiline from "@/components/ui/multiline";
import { Checkbox } from "@/components/ui/checkbox";
import Avatar from "@/components/ui/avatar";
import GoogleContacts from "@/components/app/events/manage/google-contacts";
import PastEventGuest from "@/components/app/events/manage/past-invite-guest";
import Quill from "quill";
import _ from "lodash";
import { inviteEmail } from "@/types/cusom-types";
import eventClientApi from "@/api-client/event-client";
import dynamic from "next/dynamic";
import { Input } from "@/components/ui/input";
import { useParticipants } from "@/hooks/use-participants";
import { toast } from "sonner";
import { getRoleDisplayName } from "@/lib/utils";
import Group from "@/components/app/events/manage/group/group";
import { usePaticipantsInvite } from "@/stores/use-guest-invite-store";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs-variant1.";
import Cookies from "js-cookie";
import { clientServerIdKey, getQueryKey } from "@key.ai/constants";
import { useQueryClient } from "@tanstack/react-query";
import { useEventStore } from "@/stores/use-event-store";
import SideDrawer from "@/components/ui/sidebar-drawer";
import CSVUpload from "@/components/app/events/manage/csv-upload";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import { pluralize } from "@/lib/label";
import EventIcon from "@/public/static/images/icons/Events-selected.svg";
import Image from "next/image";
import { useSession } from "next-auth/react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { X } from "lucide-react";
import NoDataView from "@/components/ui/no-data-view";

const MailEditor = dynamic(() => import("@/components/editor/mail-editor"), {
  ssr: false
});

const GuestPreview = ({ emails, handleRemoveEmail }: any) => {
  const [search, setSearch] = useState<string>("");

  const filteredEmails = emails.filter((email: any) =>
    email?.email.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="flex flex-col">
      <div className="px-l mt-[20px]">
        <Input
          variant="medium-s"
          className="rounded-xs w-full px-xl"
          inputClassName="w-full"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          leftSectionBgClassName="bg-transparent !mr-2 items-center content-center p-none m-auto"
          leftSection={
            <SearchNormal1 size="24" className="text-color-secondary" variant="Outline" />
          }
          rightSectionClassName="py-none px-none pr-m-nudge"
          rightSection={
            search && (
              <X
                size={20}
                onClick={() => {
                  setSearch("");
                }}
              ></X>
            )
          }
        />
      </div>
      <div className="mt-[20px] overflow-y-auto h-[calc(100dvh-300px)]">
        {filteredEmails?.length ? (
          filteredEmails.map((email: any, index: number) => (
            <Fragment key={index}>
              <div className="px-l flex items-center justify-between p-s">
                <Text variant={"body_one"}>{email?.email}</Text>
                <Trash
                  onClick={() => handleRemoveEmail(email?.email)}
                  className="text-danger-500 cursor-pointer"
                />
              </div>
              <div className="border-b border-gray-200 w-full my-l"></div>
            </Fragment>
          ))
        ) : (
          <div className="content-center h-full mx-auto text-center">
            <NoDataView title={`No guest found.`} imageKey={"guest"} />
          </div>
        )}
      </div>
    </div>
  );
};

const tabs = [
  {
    key: "email",
    title: "Email"
  },
  {
    key: "contact",
    title: "Contact"
  },
  {
    key: "group",
    title: "Group"
  },
  {
    key: "pastEvents",
    title: "Past Events"
  },
  {
    key: "csv",
    title: "CSV"
  }
];

type ViewKeys = "contact" | "email" | "pastEvents" | "group" | "csv";

export const GuestInviteModal = ({ setOpenGuestInvite }: any) => {
  const [emails, setEmails] = useState<any>([]);

  const { showPaticipantsBar, setShowPaticipantsBar } = usePaticipantsInvite();

  const [quill, setQuill] = useState<Quill | null>(null);

  const [inviteLoading, setInviteLoading] = useState<boolean>(false);

  const [view, setView] = useState<ViewKeys>("email");

  const [emailView, setEmailView] = useState<boolean>(false);
  const [guestPreview, setGuestPreview] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { eventData } = useEventStore();

  const { data: userSession } = useSession();

  const { isOpen, onClose, type, data } = useModal();
  const { role }: any = data;

  const [subject, setSubject] = useState<string>(
    `Hi, ${"We"} invite you to join ${eventData?.name}.`
  );

  const community_id = Cookies.get(clientServerIdKey);

  const isModalOpen = isOpen && type === "inviteGuest";

  const handleTabChange = (value: ViewKeys) => {
    setView(value);
    setShowPaticipantsBar(true);
  };

  useEffect(() => {
    if (eventData) {
      if (role === "guest") {
        setSubject(
          `You've been invited to attend ${eventData.name} by ${userSession?.user.firstName}`
        );
      } else {
        setSubject(
          `${userSession?.user.firstName} has invited you to be a ${getRoleDisplayName(role)} at ${eventData.name}!`
        );
      }
    }
    if (quill) {
      if (role === "guest") {
        quill.setText(
          `Great news! ${userSession?.user.firstName} has invited you to join ${eventData.name} 🥳`
        );
      } else {
        quill.setText(
          `Exciting news! ${userSession?.user.firstName} has invited you to be a ${getRoleDisplayName(role)} at ${eventData.name}! 🎉`
        );
      }
    }
  }, [eventData, quill, userSession, role]);

  const handleToggleEmailSelection = (emailToToggle: any) => {
    setEmails(
      emails.map((email: any) =>
        email.email === emailToToggle.email ? { ...email, selected: !email.selected } : email
      )
    );
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setEmails(emails.filter((email: any) => email?.email !== emailToRemove));
  };

  const countSelectedEmails = () => {
    return emails.filter((email: any) => email.selected).length;
  };

  const handleSendInvite = async () => {
    if (!quill) return;
    const description_html = quill.getSemanticHTML();
    setInviteLoading(true);
    try {
      const data = {
        community_id: community_id,
        email: {
          subject: subject,
          body: btoa(encodeURIComponent(description_html))
        },
        invitees: [
          {
            role: role,
            emails: emails
              .filter(({ selected }: { selected: boolean }) => selected)
              .map((email: any) => email.email)
          }
        ]
      };
      const response = await eventClientApi
        .post(`api/v2/events/${eventData?.id}/event-participations`, {
          json: { ...data }
        })
        .json<{ data: []; error_data: [] }>();

      await queryClient.refetchQueries({
        queryKey: [getQueryKey("EVENT_GUEST", { eventId: eventData?.id })]
      });

      handleClose();

      const successInvitationMessage = response.data?.length
        ? `${response?.data?.length / 2} ${pluralize("user", response?.data?.length / 2)} invited successfully.`
        : "Invitation processed successfully. No new invitation has been sent.";

      handleClose();
      toast.success(`${successInvitationMessage}`);
    } catch (error: any) {
      console.log(error);
    } finally {
      setInviteLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (emailView && quill && emails?.length) {
      return handleSendInvite();
    }
    setGuestPreview(false);
    setEmailView(true);
  };

  const handleCancel = async () => {
    if (!emailView) {
      return handleClose();
    }
    setEmailView(false);
  };

  const handleClose = async () => {
    setEmails([]);
    setEmailView(false);
    onClose();
    setShowPaticipantsBar(true);
  };

  const viewComponents: Record<ViewKeys, React.ComponentType<any>> = {
    contact: GoogleContacts,
    email: EmailInvite,
    pastEvents: PastEventGuest,
    group: Group,
    csv: CSVUpload
  };

  const renderViewComponent = (view: ViewKeys, props: any): JSX.Element | null => {
    const Component = viewComponents[view];
    return Component ? <Component {...props} /> : null;
  };

  return (
    <SideDrawer
      isOpen={isModalOpen}
      onClose={() => onClose()}
      className="md:w-[50%] w-full lg:w-[30%] bg-container bg-white rounded-none px-0 overflow-hidden"
    >
      <div className="py-m px-l flex justify-between items-start">
        <div className="flex gap-m items-start">
          <div
            className={`transition-all duration-300 ease-in-out mt-[3px] ${
              guestPreview ? "opacity-100 block" : "opacity-0 hidden"
            }`}
            onClick={() => setGuestPreview(false)}
          >
            <ArrowLeft2 className="transform transition-transform duration-300 ease-in-out" />
          </div>
          <div
            className={`transition-all duration-300 ease-in-out ${
              !guestPreview ? "ml-0" : "ml-[1px]"
            }`}
          >
            <Text
              variant={"title_three"}
              className="font-semibold transition-all duration-300 ease-in-out"
            >
              Invite Guests ({emails?.length})
            </Text>

            {!guestPreview ? (
              <Text
                variant={"body_one"}
                className="text-color-tertiary underline cursor-pointer"
                onClick={() => setGuestPreview(true)}
              >
                View list
              </Text>
            ) : (
              <Text
                variant={"body_one"}
                className="text-danger-500  cursor-pointer"
                onClick={() => {
                  setEmails([]);
                  setGuestPreview(false);
                }}
              >
                Remove All
              </Text>
            )}
          </div>
        </div>

        <div>
          <X className="cursor-pointer" onClick={onClose} />
        </div>
      </div>
      <div className="flex flex-row mb-3 mt-m">
        {!emailView && !guestPreview
          ? tabs.map((section, index) => (
              <Button
                key={index}
                variant="text"
                radius="none"
                layerClassName="hover:bg-transparent"
                onClick={() => handleTabChange(section.key as ViewKeys)}
                className={`h-fit w-auto md:w-[150px] ${view === section.key ? "border-b-1 border-black" : ""}`}
              >
                {section.title}
              </Button>
            ))
          : null}
      </div>
      <div className="border-b border-gray-200 w-full mt-[-12px]"></div>
      <div className=" w-full  h-auto">
        {guestPreview ? (
          <GuestPreview handleRemoveEmail={handleRemoveEmail} emails={emails} />
        ) : emailView ? (
          <div className="px-l">
            <Text variant={"body_one"} className="mt-xl ">
              Subject
            </Text>

            <Input
              variant="medium-s"
              className="rounded-xs w-full px-xl"
              inputClassName="w-full"
              placeholder="Subject"
              value={subject}
              onChange={(e: any) => setSubject(e.target.value)}
            />

            <Text variant={"body_one"} className="mt-xl ">
              About Event
            </Text>

            <Box className="field-border">
              <div className="">
                <MailEditor quill={quill} setQuill={setQuill} />
              </div>
            </Box>
            <Multiline
              className="bg-transparent !px-0 !mx-0 mt-xl"
              icon={<Image src={EventIcon} alt="events" className="w-[60px]" />}
              titleText={"We will send (guest/featured/host) an invite link"}
              text1={
                "Hosts & featured guest will automatically be added to the guest list upon accepting the invite. Guests will still have to register for the event."
              }
              titleTextSize={"medium"}
              text1Size={"small"}
            />
          </div>
        ) : (
          renderViewComponent(view, { emails, setEmails, role, setGuestPreview })
        )}

        <Box className="w-full backdrop-blur-30 flex gap-m justify-center mt-s  fixed bottom-0 mx-auto">
          {emailView ? (
            <Button className="w-[180px]" variant="secondary" onClick={handleCancel}>
              <Text variant={"body_two"}>Back</Text>
            </Button>
          ) : null}
          <Button
            className="w-[180px]"
            onClick={handleSubmit}
            disabled={!emails?.length || inviteLoading}
            loading={inviteLoading}
            loaderProps={{ size: "xs" }}
          >
            {!emailView ? (
              <Text variant={"body_two"} className="items-center flex gap-xs">
                <Add /> Invite
              </Text>
            ) : (
              <Text variant={"body_two"} className=" items-center flex gap-xs">
                <Send2 variant="Bold" />
                Send Invite
              </Text>
            )}
          </Button>
        </Box>
      </div>
    </SideDrawer>
  );
};
