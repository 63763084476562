import { useInfiniteQuery } from "@tanstack/react-query";
import { ApiResponsePagination } from "@/types/api";

import { getQueryKey } from "@key.ai/constants";
import { compact } from "lodash";
import baseClient from "@/api-client/base";
import { useSession } from "next-auth/react";

export const useGoogleContacts = ({
  accessToken,
  query,
  selectAll
}: {
  accessToken: string;
  query: string;
  selectAll: boolean;
}) => {
  const { data: session } = useSession();

  const queryKey = getQueryKey("GOOGLE_CONTACTS", { userId: session?.user?.id || "" });

  const fetchGoogleContacts = async ({ pageParam }: { pageParam: unknown }) => {
    let url;
    try {
      if (query) {
        url = `https://people.googleapis.com/v1/people:searchContacts?readMask=names,emailAddresses,photos&pageSize=${selectAll ? 30 : 30}${pageParam ? `&pageToken=${pageParam}` : ""}&query=${encodeURIComponent(query)}`;
      } else {
        url = `https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses,photos&pageSize=${selectAll ? 2000 : 50}${pageParam ? `&pageToken=${pageParam}` : ""}`;
      }

      const response = await baseClient
        .get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .json<any>();

      return response;
    } catch (error) {
      sessionStorage.removeItem(`google_auth_token_${session?.user?.id}`);
    }
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, isLoading, refetch } =
    useInfiniteQuery<ApiResponsePagination<any>>({
      queryKey: compact([queryKey, query, selectAll]),
      queryFn: fetchGoogleContacts,
      getNextPageParam: (lastPage: any) => {
        return lastPage?.nextPageToken;
      },
      initialPageParam: ""
    });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isLoading,
    refetch
  };
};
