import React from "react";
import { ArrowRight2 } from "iconsax-react";
import Divider from "@/components/ui/divider";
import Flex from "@/components/ui/flex";
import Typography from "@/components/ui/typography";
import { Tooltip } from "@/components/custom/tooltip";
import { IconInfoCircle } from "@tabler/icons-react";

interface SectionHeaderProps {
  showInfoIcon?: boolean;
  titleText?: any;
  infotooltipContent?: any;
  infotooltipContentTitle?: string;
  action?: any;
  lChevron?: boolean;
  topBorder?: boolean;
  bold?: boolean;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
}

const SectionHeader = ({
  titleText,
  showInfoIcon,
  action,
  infotooltipContent,
  infotooltipContentTitle,
  lChevron = false,
  topBorder = false,
  bold = false,
  className,
  containerClassName,
  disabled = false
}: SectionHeaderProps) => {
  const titleClassName = `${className} ${disabled ? "text-gray-500" : "text-current"}`;

  return (
    <>
      {topBorder && <Divider />}
      <Flex
        direction="row"
        justify={"between"}
        align={"center"}
        className={` py-s bg-transparent ${containerClassName}`}
      >
        <Flex direction="row" justify={"start"} align={"center"}>
          {lChevron && <ArrowRight2 size="16" color="#697689" className="m-1" />}
          <div className={titleClassName}>{titleText}</div>
          {showInfoIcon && (
            <Tooltip description={infotooltipContent} title={infotooltipContentTitle}>
              <IconInfoCircle size={17} color="#000000" style={{ margin: "3px" }} />
            </Tooltip>
          )}
        </Flex>
        <div className="cursor-pointer">{action}</div>
      </Flex>
    </>
  );
};

export default SectionHeader;
