import React, { Fragment, useState } from "react";
import Avatar from "@/components/ui/avatar";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Text } from "@/components/ui/text";
import { ArrowLeft2, SearchNormal1 } from "iconsax-react";

import { getUserInitials } from "@/lib/utils";
import Multiline from "@/components/ui/multiline";
// import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs-variant1.";
import NoDataView from "@/components/ui/no-data-view";
import { Check, X } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";

const GroupGuests = ({
  setOpened,
  selectedGroup,
  users,
  emails,
  handleToggleEmailSelection,
  handleSelectAll
}: any) => {
  const [search, setSearch] = useState<string>("");

  const filteredEmails = users.filter((email: any) =>
    email?.email.toLowerCase().includes(search.toLowerCase())
  );

  const isSelectAll = users.every((user: any) =>
    emails.some((email: any) => email.email === user.email && email.selected === true)
  );

  return (
    <div>
      <div className="my-s">
        <Multiline
          className="bg-transparent px-0 !mx-0"
          icon={
            <div className="relative flex  items-center cursor-pointer">
              <ArrowLeft2
                className="mr-m cursor-pointer"
                onClick={() => {
                  setOpened(false);
                }}
              />
              <Avatar
                imgSrc={selectedGroup?.image}
                size={56}
                activeRing={false}
                variant="image"
                present={false}
                presence={false}
                presenceState="blocked"
              />
            </div>
          }
          titleText={
            <Text
              variant={"title_three"}
              className="font-semibold transition-all duration-300 ease-in-out "
            >
              {selectedGroup?.name}
            </Text>
          }
          text1={`${selectedGroup?.numberOfGuests} People`}
          titleTextSize={"medium"}
          text1Size={"small"}
          action={
            <div className="flex items-center gap-s mr-xs">
              <Checkbox
                id="select-all"
                checked={isSelectAll}
                onCheckedChange={(e: boolean) => {
                  handleSelectAll(e);
                }}
              />
              <Text className="ml-1">Select All</Text>
            </div>
          }
        />
      </div>
      <div className="border-b border-gray-200 w-full"></div>

      <div className="px-l mt-2xl">
        <Input
          variant="medium-s"
          className="rounded-xs w-full px-xl"
          inputClassName="w-full"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          leftSectionBgClassName="bg-transparent !mr-2 items-center content-center p-none m-auto"
          leftSection={
            <SearchNormal1 size="24" className="text-color-secondary" variant="Outline" />
          }
          rightSectionClassName="py-none px-none pr-m-nudge"
          rightSection={
            search && (
              <X
                size={20}
                onClick={() => {
                  setSearch("");
                }}
              ></X>
            )
          }
        />
      </div>
      <ScrollArea
        className="h-[calc(100dvh-200px)]  flex flex-col mt-m"
        viewportClassName="h-[calc(100dvh-200px)]"
        type="always"
      >
        {filteredEmails?.length ? (
          filteredEmails?.map((user: any, index: number) => {
            const initals = getUserInitials(user);
            const isSelected = !!emails.find((e: any) => e.email === user?.email);

            return (
              <Fragment>
                <div onClick={() => handleToggleEmailSelection(user)}>
                  <Multiline
                    key={index}
                    icon={
                      <div className="flex gap-m items-center">
                        <Avatar
                          text={initals}
                          imgSrc={user?.profile_photo}
                          size={56}
                          activeRing={true}
                          variant={user?.profile_photo ? "image" : "initials"}
                          present={false}
                          presence={false}
                        />
                      </div>
                    }
                    text1={user?.email}
                    action={isSelected && <Check />}
                    titleTextSize={"medium"}
                    text1Size={"small"}
                  />
                </div>
                <div className="border-b border-gray-200 w-full"></div>
              </Fragment>
            );
          })
        ) : (
          <div className="content-center h-full mx-auto text-center">
            <NoDataView title={`No user found.`} imageKey={"guest"} />
          </div>
        )}
      </ScrollArea>
    </div>
  );
};

export default GroupGuests;
