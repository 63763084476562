import React, { Fragment, useEffect, useState } from "react";
import eventClientApi from "@/api-client/event-client";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import Avatar from "@/components/ui/avatar";
import Box from "@/components/ui/box";
import { Checkbox } from "@/components/ui/checkbox";
import { CollapsibleComponent } from "@/components/ui/collapse";
import { Input } from "@/components/ui/input";
import { Loader } from "@/components/ui/loader";
import Multiline from "@/components/ui/multiline";
import SectionHeader from "@/components/ui/section-header";
import { Skeleton } from "@/components/ui/skeleton";
import { Text } from "@/components/ui/text";
import useInView from "@/hooks/use-in-view";
import { cleanObject } from "@/lib/helper";
import { eventImageSizeMapper, getCurrentEpoc, getUserInitials } from "@/lib/utils";
import { inviteEmail } from "@/types/cusom-types";
import { ArrowDown2, ArrowLeft2, SearchNormal1 } from "iconsax-react";
import _ from "lodash";
import { Check, X } from "lucide-react";
import { useSession } from "next-auth/react";
import { motion } from "framer-motion";
import { ScrollArea } from "@/components/ui/scroll-area";
import NoDataView from "@/components/ui/no-data-view";
import useDebounce from "@/hooks/use-debounce";

const prepareGetParticipantsURL = (data: {
  eventId: string;
  roles?: string;
  sortBy?: string[];
  status?: string;
  searchText?: string;
  details?: boolean;
  limit?: number;
  page?: number;
  searchParam?: string;
  searchValue?: string;
  checkin_epoc?: boolean;
}) => {
  return `api/v2/events/${data?.eventId}/event-participations?roles=${data.roles || ""}&statuses=${data.status || ""}&name=${encodeURIComponent(data.searchText || "")}&sort=${
    data.sortBy && data.sortBy.length ? data.sortBy.map((item) => `${item}:ASC`).join(",") : ""
  }${data.details === false ? "&details=false" : ""}${
    data.searchParam && data.searchValue
      ? "&searchParam=" + data.searchParam + "&searchValue=" + data.searchValue
      : ""
  }${typeof data.limit === "number" ? "&limit=" + data.limit : ""}${
    typeof data.page === "number" ? "&page=" + data.page : ""
  }${data.checkin_epoc === true ? "&checkin_epoc=true" : ""}`;
};

const PastEventGuest = ({ setEmails, role, emails }: any) => {
  const [events, setEvents] = useState<any>([]);
  const [search, setSearch] = useState<string>("");

  const [opened, setOpened] = useState<string>("");

  const [selected, setSelected] = useState<string>("");

  const [selectedEvent, setSelectedEvent] = useState<any>({});
  const [SelectedEventParticipationCount, setSelectedEventParticipationCount] = useState<number>(0);

  const [userLoading, setUserLoading] = useState<boolean>(false);

  const [loading, setLoading] = useState<any>(true);
  const [users, setUsers] = useState<any>([]);

  const [page, setPage] = useState<any>({});
  const [eventPage, setEventPage] = useState<number>(1);
  const { data: session }: any = useSession();

  const [ref, isInView] = useInView({
    threshold: 1.0
  });

  const [eventRef, eventIsInView] = useInView({
    threshold: 1.0
  });

  const finalSearchValue = useDebounce(search, 300);

  const fetchPastEvents = async () => {
    try {
      const { data } = await eventClientApi
        .get(`api/v3/users/${session?.user?.id}/events`, {
          searchParams: cleanObject({
            page: eventPage,
            limit: 10,
            role: "creator",
            status: "published",
            date_operator: "lt",
            current_epoc: getCurrentEpoc()
          })
        })
        .json<any>();
      if (!data?.events?.length) return;
      setEventPage((prevState: number) => prevState + 1);
      setEvents((prevState: any) => [...prevState, ...data?.events]);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (eventIsInView) {
      fetchPastEvents();
    }
  }, [eventIsInView, eventPage]);

  const handleSelectGuest = async (id: string, toggle: boolean) => {
    if (!toggle) {
      setUsers((prevState: any) => {
        if (!prevState[id]) return prevState;
        return {
          ...prevState,
          [id]: prevState[id].map((user: any) => ({ ...user, selected: false }))
        };
      });
      return setEmails(emails.filter((email: any) => email.eventId !== id));
    }
    const apiUrl = prepareGetParticipantsURL({
      eventId: id || "",
      roles: "guest,featured_guest,host",
      limit: 5000,
      page: 1
    });

    try {
      const { data } = await eventClientApi.get(apiUrl).json<any>();
      const emailsData: inviteEmail[] = _.flatten(Object.values(data)).map((user: any) => {
        return {
          ...user?.User,
          selected: false
        };
      });
      const invite = emailsData?.map((d: any) => ({
        email: d.email,
        role: role,
        selected: true,
        eventId: id
      }));
      setEmails((prevState: any) => [...prevState, ...invite]);
      setUsers((prevState: any) => {
        if (!prevState[id]) return prevState;
        return {
          ...prevState,

          [id]: prevState[id].map((user: any) => ({ ...user, selected: toggle }))
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleSelectAllGuests = async (toggle: boolean) => {
    const allEventIds = events?.map((event: any) => event?.Event?.id);
    const promises = allEventIds.map((id: string) => handleSelectGuest(id, toggle));
    try {
      await Promise.all(promises);
      console.log("All selections updated successfully.");
    } catch (error) {
      console.error("Error updating selections: ", error);
    }
  };

  const handleFetchPastEventGuests = async () => {
    const apiUrl = prepareGetParticipantsURL({
      eventId: selected || "",
      roles: "guest,featured_guest,host",
      limit: 15,
      page: page[selected] || 1,
      searchText: finalSearchValue
    });

    try {
      setUserLoading(true);

      const { data } = await eventClientApi.get(apiUrl).json<any>();
      const emailsData: inviteEmail[] = _.flatten(Object.values(data)).map((user: any) => {
        return {
          ...user?.User,
          selected: false,
          role: role
        };
      });

      if (!emailsData.length) return;

      setPage((prevState: any) => ({
        ...prevState,
        [selected]: (prevState?.[selected] ?? 1) + 1
      }));
      setUsers((prevState: any) => ({
        ...prevState,
        [selected]: prevState[selected] ? [...emailsData, ...prevState[selected]] : [...emailsData]
      }));

      setOpened(selected);
    } catch (error) {
      console.log(error);
    } finally {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    handleFetchPastEventGuests();
  }, [finalSearchValue]);

  useEffect(() => {
    if (isInView && selected) {
      handleFetchPastEventGuests();
    }
  }, [isInView]);

  const updateSelectedField = (_user: any, event_id: string) => {
    setEmails((prevEmails: any) =>
      prevEmails.map((email: any) =>
        email.email === _user.email ? { ...email, selected: !email.selected } : email
      )
    );

    setUsers((prevState: any) => {
      if (prevState[event_id]) {
        return {
          ...prevState,
          [event_id]: prevState[event_id].map((user: any) =>
            user.email === _user.email ? { ...user, selected: !user.selected } : user
          )
        };
      }
      return prevState;
    });
  };

  const slideAnimation = {
    hidden: { x: "100%" }, //
    visible: { x: 0 },
    exit: { x: "100%" }
  };

  const handleToggleEmailSelection = (user: any) => {
    setEmails((prevEmails: any) => {
      const isAlreadySelected = prevEmails.some((e: any) => e.email === user.email);
      if (isAlreadySelected) {
        return prevEmails.filter((e: any) => e.email !== user.email);
      } else {
        return [...prevEmails, { email: user?.email, groupId: opened, role, selected: true }];
      }
    });
  };

  const isSelectAll = users?.[selectedEvent?.id]?.every((user: any) =>
    emails.some((email: any) => email?.email === user?.email && email?.selected === true)
  );

  const countUsersWithEventId = emails.filter(
    ({ eventId }: { eventId: string }) => eventId !== undefined
  ).length;

  const handleSelectAll = (e: any) => {
    if (e) {
      const userData = users?.[selectedEvent?.id].map((data: any) => {
        return { email: data?.email, groupId: opened, role, selected: true };
      });
      setEmails(userData);
    } else {
      setEmails([]);
    }
  };

  return (
    <div>
      {selectedEvent?.id ? (
        <motion.div
          className="bg-white p-ml z-20 h-full w-full absolute left-0 right-0 bottom-0 top-0"
          variants={slideAnimation}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ type: "tween", duration: 0.05 }}
        >
          <div>
            <div className="my-s">
              <Multiline
                className="bg-transparent px-0 !mx-0"
                icon={
                  <div className="relative flex  items-center cursor-pointer">
                    <ArrowLeft2
                      className="mr-m cursor-pointer"
                      onClick={() => {
                        setOpened("");
                        setSelectedEvent({});
                        setSearch("");
                      }}
                    />
                    <Avatar
                      imgSrc={eventImageSizeMapper(selectedEvent?.image_map)}
                      size={56}
                      activeRing={false}
                      variant="image"
                      present={false}
                      presence={false}
                      presenceState="blocked"
                    />
                  </div>
                }
                titleText={
                  <Text
                    variant={"title_three"}
                    className="font-semibold transition-all duration-300 ease-in-out "
                  >
                    {selectedEvent?.name}
                  </Text>
                }
                text1={`Showing ${users?.[selectedEvent?.id]?.length || 0} / ${SelectedEventParticipationCount} People`}
                titleTextSize={"medium"}
                text1Size={"small"}
                action={
                  <div className="flex items-start gap-s mr-xs">
                    <Checkbox
                      id="select-all"
                      checked={isSelectAll}
                      onCheckedChange={(e: boolean) => {
                        handleSelectAll(e);
                      }}
                    />
                    <Text className="ml-1">Select All({users?.[selectedEvent?.id]?.length})</Text>
                  </div>
                }
              />
            </div>
            <div className="border-b border-gray-200 w-full"></div>

            <div className="px-l mt-2xl">
              <Input
                variant="medium-s"
                className="rounded-xs w-full px-xl"
                inputClassName="w-full"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setUsers([]);
                  setPage((prevState: any) => ({
                    ...prevState,
                    [selected]: 1
                  }));
                  setSearch(e.target.value);
                }}
                leftSectionBgClassName="bg-transparent !mr-2 items-center content-center p-none m-auto"
                leftSection={
                  <SearchNormal1 size="24" className="text-color-secondary" variant="Outline" />
                }
                rightSectionClassName="py-none px-none pr-m-nudge"
                rightSection={
                  search && (
                    <X
                      size={20}
                      onClick={() => {
                        setUsers([]);
                        setPage((prevState: any) => ({
                          ...prevState,
                          [selected]: 1
                        }));
                        setSearch("");
                      }}
                    ></X>
                  )
                }
              />
            </div>
            <ScrollArea
              className="h-[calc(100dvh-200px)]  flex flex-col mt-m"
              viewportClassName="h-[calc(100dvh-200px)]"
              type="auto"
            >
              <div className="px-xl">
                {users?.[selectedEvent?.id]?.length ? (
                  <div>
                    {users?.[selectedEvent?.id]?.map((user: any, index: number) => {
                      if (!user) return;
                      const initals = getUserInitials(user);
                      const isSelected = !!emails.find((e: any) => e.email === user?.email);

                      return (
                        <div onClick={() => handleToggleEmailSelection(user)}>
                          <Multiline
                            key={index}
                            className="!px-0"
                            icon={
                              <div className="flex gap-m items-center">
                                {/* <Checkbox
                                variant="rounded"
                                onCheckedChange={() => updateSelectedField(user, event?.id)}
                                checked={user?.selected}
                              /> */}
                                <Avatar
                                  text={initals}
                                  imgSrc={user?.profile_photo}
                                  size={56}
                                  activeRing={true}
                                  variant={user?.profile_photo ? "image" : "initials"}
                                  present={false}
                                  presence={false}
                                />
                              </div>
                            }
                            text1={user?.email}
                            titleTextSize={"medium"}
                            text1Size={"small"}
                            action={isSelected && <Check />}
                          />
                          <div className="border-b border-gray-200 w-full"></div>
                        </div>
                      );
                    })}
                  </div>
                ) : userLoading ? (
                  <div className="w-full">
                    <Loader className="mx-auto" />
                  </div>
                ) : null}

                <div ref={ref} />
              </div>
            </ScrollArea>
          </div>
        </motion.div>
      ) : null}
      <div>
        <div className="w-full">
          <div className="py-m px-l  bg-zinc-100 flex items-center justify-between">
            <div>
              <Text variant={"subtitle_one"} className="font-semibold">
                {`Selected ${countUsersWithEventId || 0} Guests`}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Box className="px-0 pt-0">
        <div className="h-[calc(100dvh-250px)]  overflow-y-auto">
          {loading ? (
            <div className="mx-auto mt-l">
              <Loader />
            </div>
          ) : events?.length ? (
            <Accordion
              type="single"
              className="w-full flex flex-col gap-s-nudge h-full shadow-none"
              collapsible
            >
              {events?.map((_event: any, index: number) => {
                const { Event: event } = _event;
                return (
                  <div
                    onClick={() => {
                      setSelectedEvent(event);
                      setSelectedEventParticipationCount(_event.participantsCount);
                      setSelected(event?.id);
                    }}
                  >
                    <Multiline
                      className="bg-transparent "
                      icon={
                        <div
                          className="flex gap-m items-center relative"
                          onClick={(e) => {
                            e.stopPropagation();
                            const isSelected = !!emails?.find((e: any) => e.eventId === event?.id)
                              ?.eventId;
                            handleSelectGuest(event?.id, !isSelected);
                          }}
                        >
                          <Avatar
                            imgSrc={eventImageSizeMapper(event?.image_map)}
                            size={56}
                            activeRing={false}
                            variant="image"
                            present={false}
                            presence={false}
                            presenceState="blocked"
                          />
                          {userLoading ? (
                            <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center bg-black opacity-75 rounded-full p-1">
                              <Loader size="small" />
                            </div>
                          ) : (
                            !!emails?.find((e: any) => e?.eventId === event?.id)?.eventId && (
                              <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center bg-black opacity-75 rounded-full p-1">
                                <Check size={16} className="text-white" />
                              </div>
                            )
                          )}
                        </div>
                      }
                      titleText={event?.name}
                      text1={`${_event?.participantsCount} People`}
                      titleTextSize={"medium"}
                      text1Size={"small"}
                    />
                    <div className="border-b border-gray-200 w-full"></div>
                  </div>
                );
              })}
            </Accordion>
          ) : (
            <Box className="bg-transparent text-center">No past events found.</Box>
          )}
          <div ref={eventRef}></div>
        </div>
      </Box>
    </div>
  );
};

export default PastEventGuest;
