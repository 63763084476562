"use client";
import React, { Fragment, useCallback } from "react";
import {
  DropEvent,
  DropzoneOptions,
  DropzoneState,
  FileRejection,
  useDropzone
} from "react-dropzone";
import { useGlobalLoaderModal } from "@/stores/use-global-loader-store";
import { convertHeicToJpeg } from "@/lib/image";
import { useModal } from "@/stores/use-modal-store";

type DropzoneProps = {
  children?: ((props: DropzoneState) => React.JSX.Element) | React.ReactNode;
  className?: string;
  openCroper?: boolean;
  handleImg?: ((file: File | null) => void) | null;
} & DropzoneOptions;

export const Dropzone = ({
  children,
  className,
  onDrop,
  handleImg = null,
  openCroper = true,
  ...props
}: DropzoneProps) => {
  const { onOpen, onClose } = useGlobalLoaderModal();
  const { onOpen: onOpenImgSelecter } = useModal();

  const onDropHandler = useCallback(
    async (accepted: any[], rejections: FileRejection[], event: DropEvent) => {
      const files = [];
      onOpen();

      for (const file of accepted) {
        if (file.type === "image/heic") {
          const _file = await convertHeicToJpeg(file);
          if (_file) files.push(_file);
        } else {
          files.push(file);
        }
      }

      onDrop?.(files, rejections, event);
      onClose();
    },
    [onDrop, onOpen, onClose]
  );

  const { getRootProps, getInputProps, ...others } = useDropzone({
    ...props,
    onDrop: onDropHandler
  });

  return (
    <Fragment>
      <div
        {...getRootProps({ className })}
        {...(!handleImg
          ? {}
          : {
              onClick: () => {
                onOpenImgSelecter("imageSelector", {
                  callback: async (file) => {
                    handleImg(file);
                  },
                  unsplashType: "COMMUNITY",
                  openCroper: openCroper
                });
              }
            })}
        role="button"
      >
        {!handleImg && <input {...getInputProps()} />}
        {typeof children === "function"
          ? children({ getRootProps, getInputProps, ...others })
          : children}
      </div>
    </Fragment>
  );
};
