"use client";
import { useModal } from "@/stores/use-modal-store";
import { Text } from "@/components/ui/text";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ManualUpload } from "@/components/invite/manual-upload";
import { BulkUpload } from "@/components/invite/bulk-upload";
import { ShareLink } from "@/components/invite/share-link";
import { useParams, usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useMemo } from "react";
import { X } from "lucide-react";
import { useMemberQuery } from "@/hooks/use-member-query";

export const InviteModal = () => {
  const { isOpen, type, onClose, onOpen } = useModal();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const { domain } = useParams<{ domain: string }>();
  const { data: me } = useMemberQuery({ memberId: "me" });

  useEffect(() => {
    const invite = searchParams.get("invite");
    if (invite && invite === "true" && domain) {
      const params = new URLSearchParams(searchParams.toString());
      params.delete("invite");
      const p = params.toString();
      onOpen("invite");
      router.push(`${pathname}${p ? `?${p}` : ""}`);
    }
  }, [searchParams, pathname, router, onOpen, domain]);

  const isModalOpen = useMemo(() => {
    return isOpen && type === "invite" && !!me;
  }, [isOpen, type, me]);

  const handleClose = () => onClose();

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent
        className="max-w-full-50px w-[550px] overflow-hidden p-0 bg-none border-none backdrop-blur-none shadow-none"
        style={{
          height: "calc(100dvh - 90px)",
          background: "transparent"
        }}
        isShowCloseBtn={false}
      >
        <div className="w-full max-h-full flex flex-col overflow-hidden">
          <div className="rounded-lg border-2 border-border bg-modal overflow-hidden py-4 shadow-lg backdrop-blur-20 flex flex-col my-auto">
            <div className="flex-shrink-0 px-4">
              <DialogHeader className="flex-shrink-0 p-l border-b-1">
                <div className="flex ">
                  <DialogTitle>Invite Member</DialogTitle>
                  <DialogClose className="ms-auto">
                    <X className="h-4 w-4" />
                  </DialogClose>
                </div>
              </DialogHeader>
            </div>
            <div className="flex-shrink-1 overflow-hidden">
              <ScrollArea className="h-full px-4">
                <div className="w-full flex-shrink-1 flex flex-col ">
                  <div className="w-full flex flex-row items-center justify-center">
                    <img
                      src={"/static/images/Invite-member.png"}
                      alt="Invite Logo"
                      className="w-[88%]"
                    />
                  </div>
                  <div className="flex flex-col gap-xl w-full pt-4 overflow-hidden">
                    <div className="flex flex-row">
                      <div className="rounded-full h-fit pt-[17px] pb-[16px] px-[27px] gap-[10px] border-1 border-black mr-xl">
                        <Text className="text-2xl font-light ">1</Text>
                      </div>
                      <ShareLink />
                    </div>
                    <div className="flex flex-row">
                      <div className="rounded-full h-fit py-[16px] px-[25px] gap-[10px] border-1 border-black mr-xl">
                        <Text className="text-2xl font-light ">2</Text>
                      </div>
                      <div className="flex flex-col w-full">
                        <Text variant="body_one_strong" className="mb-2 ">
                          Email
                        </Text>

                        <ManualUpload />
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="rounded-full h-fit py-[16px] px-[25px] gap-[10px] border-1 border-black mr-xl">
                        <Text className="text-2xl font-light ">3</Text>
                      </div>
                      <BulkUpload />
                    </div>
                  </div>
                </div>
              </ScrollArea>
            </div>
            <div className="flex-shrink-0 px-4"></div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
