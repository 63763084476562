import React, { RefObject, useRef, useState } from "react";
import { Input } from "@/components/ui/input";
import { Text } from "@/components/ui/text";
import { Button } from "@/components/ui/button";
import { EMAIL_REGEX } from "@key.ai/constants";
import { toast } from "sonner";

const regex = /[\,]$/;
export const EMAIL_GMAIL_REGEX = /<([^>]+)>/;

const EmailInvite = ({ emails, setEmails, role }: any) => {
  const [emailInput, setEmailInput] = useState<any>("");

  const ref: RefObject<HTMLInputElement> = useRef(null);

  const isValidEmail = (email: string) => {
    return EMAIL_REGEX.test(email.trim());
  };

  const handleEmailInputChange = (e: any) => {
    if (regex.test(e.target.value)) {
      return addEmails();
    }
    setEmailInput(e.target.value);
  };

  const addEmails = () => {
    const newEmails = emailInput
      .split(",")
      .map((email: any) => email.trim())
      .map((emailWithOptionalName: string) => {
        const match = emailWithOptionalName.match(EMAIL_GMAIL_REGEX);
        if (match) {
          const email = match[1].trim();
          return email;
        } else {
          return emailWithOptionalName.trim();
        }
      });
    const invalidEmails = newEmails.filter((email: any) => !isValidEmail(email));

    if (invalidEmails.length > 0) {
      toast.error("Given emails are not valid.");
    } else {
      const uniqueEmails = [...new Set(newEmails)];
      const existingEmails = emails.map((email: any) => email.email.toLowerCase());
      const emailsToAdd = uniqueEmails
        .filter((email: any) => !existingEmails.includes(email.toLowerCase()))
        .map((email: any) => ({ email, selected: true, role: role }));
      setEmails([...emails, ...emailsToAdd]);
      setEmailInput("");
      ref?.current?.focus();
    }
  };

  const handleAdd = (e: React.KeyboardEvent) => {
    if (!emailInput) return;
    if (e.key === "Enter" || e.key === "Tab") {
      addEmails();
    }
  };

  return (
    <div className="px-l">
      <div className="mt-xl">
        <div className="flex gap-m items-center">
          <div className="w-full">
            <Input
              variant="medium-s"
              className="rounded-xs w-full px-xl h-[44px]"
              inputClassName="w-full"
              placeholder="Enter Emails"
              value={emailInput}
              onChange={handleEmailInputChange}
              onKeyDown={handleAdd}
              ref={ref}
            />
          </div>
          <Button variant="primary" className="h-[42px]" onClick={addEmails} disabled={!emailInput}>
            Add
          </Button>
        </div>

        <Text variant="caption_one" className="mt-1 text-color-tertiary">
          Press Enter or Tab to add a new email.
        </Text>
      </div>
    </div>
  );
};

export default EmailInvite;
