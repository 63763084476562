import React, { useState } from "react";
import eventClientApi from "@/api-client/event-client";
import Avatar from "@/components/ui/avatar";
import Box from "@/components/ui/box";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Text } from "@/components/ui/text";
import { Button } from "@/components/ui/button";
import { ArrowLeft2, DocumentUpload, Trash } from "iconsax-react";

import { toast } from "sonner";
import IconContainer from "@/components/ui/icon-container";
import Multiline from "@/components/ui/multiline";
import { useCSVReader } from "react-papaparse";
import { EMAIL_REGEX, isValidEmail } from "@key.ai/constants";
import SectionHeader from "@/components/ui/section-header";
import { MotionConfig } from "framer-motion";
import GroupGuests from "./group-users";
// import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs-variant1.";

const removeDuplicatesByEmail = (arr: any) => {
  const seen = new Set();
  return arr.filter((item: any) => {
    const duplicate = seen.has(item?.email);
    seen.add(item?.email);
    return !duplicate;
  });
};

const AddMember = ({ selectedGroup, setAddMember, setShowPaticipantsBar, getAllGroups }: any) => {
  const [guestList, setGuestList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isInvalidCSV, setIsInvalidCSV] = useState<boolean>(false);
  const [guestsToDelete, setGuestToDelete] = useState<any>([]);
  const [emailInput, setEmailInput] = useState<any>("");
  const [view, setView] = useState<string>("email");

  const { CSVReader } = useCSVReader();

  const handleAddGuest = async () => {
    try {
      setLoading(true);
      const guestToBeAdd = guestList?.map((guest: any) => ({
        email: guest.email,
        id: guest?.id
      }));
      const group = {
        groupId: selectedGroup?.id,
        guestsToDelete: guestsToDelete,
        guests: removeDuplicatesByEmail(guestToBeAdd)
      };
      const response = await eventClientApi.post(`api/v2/guestlist/group/guestlist`, {
        json: group
      });
      if (response.status === 200) {
        getAllGroups();
        setShowPaticipantsBar(true);
        setAddMember(false);
      }
      toast.success("Guest added successfully");
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveEmail = (email: string) => {
    setGuestToDelete((prevState: any) => [...prevState, email]);
    setGuestList((prevState: any) => {
      const removeEmail = prevState?.filter((v: any) => v.email !== email);
      return removeEmail;
    });
  };

  const handleReadData = ({ data }: any) => {
    setIsInvalidCSV(false);
    if ((data[0][0] || "").toLowerCase()?.includes("email")) {
      const emails = data
        .map((col: string[]) => col[0])
        .filter((email: string) => EMAIL_REGEX.test(email.trim()))
        .map((email: string) => {
          return { email: email.trim(), selected: true };
        });
      if (emails?.length) {
        setGuestList((prevState: any) => removeDuplicatesByEmail([...emails, ...prevState]));
      } else {
        toast.error("Please upload a file containing valid email addresses.");
      }
    } else {
      toast.error("Please upload a valid file, refer to the sample file for guidance.");
    }
    setIsInvalidCSV(true);
  };

  const handleToggleEmailSelection = (emailToToggle: any) => {
    setGuestList(
      guestList.map((email: any) =>
        email.email === emailToToggle.email ? { ...email, selected: !email.selected } : email
      )
    );
  };

  const handleRemoveAll = () => {
    setGuestList([]);
  };

  const handleCancel = () => {
    setAddMember(false);
    setShowPaticipantsBar(true);
  };

  const handleEmailInputChange = (e: any) => {
    setEmailInput(e.target.value);
  };

  const addEmails = (event: any) => {
    if (event.key !== "Enter") return;
    const newEmails = emailInput.split(",").map((email: any) => email.trim());
    const invalidEmails = newEmails.filter((email: any) => !isValidEmail(email));

    if (invalidEmails.length > 0) {
      toast.error("Given emails are not valid.");
    } else {
      const uniqueEmails = [...new Set(newEmails)];
      const existingEmails = guestList.map((email: any) => email.email.toLowerCase());
      const emailsToAdd = uniqueEmails
        .filter((email: any) => !existingEmails.includes(email.toLowerCase()))
        .map((email: any) => ({ email, selected: true }));
      setGuestList([...guestList, ...emailsToAdd]);
      setEmailInput("");
    }
  };

  const handleTabChange = (value: string) => {
    setView(value);
  };

  return (
    <div className="h-full">
      <SectionHeader
        containerClassName="mt-s px-s"
        titleText={
          <Text
            variant={"title_three"}
            className="font-semibold transition-all duration-300 ease-in-out flex items-center"
          >
            <ArrowLeft2
              className="mr-m cursor-pointer"
              onClick={() => {
                handleCancel();
              }}
            />
            Add Group Member
          </Text>
        }
        action={
          <div className="flex gap-m items-center">
            <Text variant={"body_two"}>{selectedGroup?.name}</Text>
          </div>
        }
      />

      <div className="mt-l px-l">
        {/* <div className="flex gap-x-m rounded-4">
            {tabs.map((section, index) => (
              <Button
                key={index}
                variant={view === section.key ? "primary" : "secondary"}
                radius="none"
                layerClassName="hover:bg-transparent"
                onClick={() => handleTabChange(section.key)}
              >
                {section.title}
              </Button>
            ))}
          </div> */}

        {view === "email" ? (
          <div>
            <SectionHeader
              containerClassName="mt-l px-0"
              titleText={
                <Text variant={"body_one"} className="text-color-secondary">
                  Enter Emails
                </Text>
              }
            />
            <div className="w-full">
              <Input
                variant="medium-s"
                className="rounded-xs w-full px-xl"
                inputClassName="w-full "
                placeholder="Enter Emails"
                value={emailInput}
                onChange={handleEmailInputChange}
                onKeyDown={addEmails}
              />
            </div>
          </div>
        ) : null}
      </div>

      <Box className="px-0 rounded-4">
        <CSVReader
          onUploadAccepted={(results: any) => {
            handleReadData(results);
          }}
        >
          {({ getRootProps, acceptedFile }: any) => (
            <div {...getRootProps()}>
              <div className="flex justify-center items-center border-dashed border h-[150px] rounded-4 mx-l">
                <div className="text-center">
                  <IconContainer
                    className="mx-auto"
                    size="default"
                    as="div"
                    icon={<DocumentUpload />}
                  />
                  <Text variant={"body_two_strong"} className="mt-l">
                    Import CSV File
                  </Text>
                  <Text className=" text-color-tertiary" variant={"caption_one"}>
                    Drop here or click here to choose
                  </Text>
                </div>
              </div>
            </div>
          )}
        </CSVReader>
      </Box>

      <div className="mt-l overflow-y-auto h-full">
        {guestList?.map((email: any, index: number) => {
          return (
            <>
              <Multiline
                className="bg-transparent px-l !mx-0"
                icon={
                  <div className="flex gap-m items-center">
                    <Checkbox
                      size="small"
                      variant="rounded"
                      checked={email.selected}
                      onCheckedChange={() => handleToggleEmailSelection(email)}
                    />
                    <Avatar
                      fallbackText="KG"
                      size={56}
                      activeRing={false}
                      variant="standard"
                      present={false}
                      presence={false}
                      presenceState="blocked"
                    />
                  </div>
                }
                titleText={""}
                text1={email.email}
                titleTextSize={"medium"}
                text1Size={"small"}
                action={
                  <div
                    className="flex flex-col justify-center items-center cursor-pointer"
                    onClick={() => handleRemoveEmail(email)}
                  >
                    <Trash className="text-red-500" />
                  </div>
                }
              />
              <div className="border-b border-gray-200 w-full"></div>
            </>
          );
        })}
        {guestList?.length ? (
          <div className="w-full text-center mt-m">
            <Text
              className="text-danger-500 cursor-pointer"
              variant={"body_two"}
              onClick={handleRemoveAll}
            >
              Remove All
            </Text>
          </div>
        ) : null}
      </div>

      <GroupGuests
        selectedGroup={selectedGroup}
        setOpened={true}
        users={guestList}
        handleToggleEmailSelection={handleToggleEmailSelection}
        emails={guestList}
        handleSelectAll={() => {}}
      />

      <div className="w-full  mt-s absolute bottom-0 left-0 right-0  bg-white">
        <div className="flex gap-m justify-center my-m">
          <Button className="w-[180px]" variant={"secondary"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="w-[180px]"
            onClick={handleAddGuest}
            disabled={!guestList?.length && loading}
            loading={loading}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddMember;
