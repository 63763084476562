import { useSession } from "next-auth/react";
import * as Yup from "yup";
import React, { useState } from "react";
import eventClientApi from "@/api-client/event-client";
import Box from "@/components/ui/box";
import { Input } from "@/components/ui/input";
import SectionHeader from "@/components/ui/section-header";
import { Text } from "@/components/ui/text";
import { Button } from "@/components/ui/button";
import { ArrowLeft2, User } from "iconsax-react";
import { FileButton } from "@/components/ui/file-button";
import { uploadFile } from "@/lib/uploadthing";
import { EndPoints } from "@/types/uploadthing";
import { toast } from "sonner";
import { useFormik } from "formik";
import { DEFAULT_IMAGE_URL } from "@/lib/utils";

const CreateGroup = ({
  setCreateMode,
  selectedGroup,
  setShowPaticipantsBar,
  getAllGroups
}: any) => {
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { data: session }: any = useSession();

  const [initalValues, setInitalValues] = useState<any>(selectedGroup);

  const Schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    image: Yup.string().nullable()
  });

  const formik = useFormik({
    initialValues: initalValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const payload = {
          ...values,
          image: values.values || DEFAULT_IMAGE_URL,
          user_id: session?.user?.id
        };
        const response = await eventClientApi
          .post(`api/v2/guestlist/group`, {
            json: payload
          })
          .json<any>();

        if (response.status === "success") {
          setCreateMode(false);
          setShowPaticipantsBar(true);
          getAllGroups();
          toast.success("Group created successfully");
        }
      } catch (err) {
        toast.success(err.message);
      } finally {
        setLoading(false);
      }
    }
  });

  const handleLogo = async (file: File | null) => {
    if (!file) return;
    setImageLoading(true);
    try {
      const { url } = await uploadFile(file as any, EndPoints.eventBanner);
      await formik.setFieldValue("image", url);
    } catch (err) {
      toast.error("Something went wrong while uploading image.");
    } finally {
      setImageLoading(false);
    }
  };

  return (
    <div>
      <SectionHeader
        containerClassName="mt-s px-s"
        titleText={
          <Text
            variant={"title_three"}
            className="font-semibold transition-all duration-300 ease-in-out flex items-center"
          >
            <ArrowLeft2
              className="mr-m cursor-pointer"
              onClick={() => {
                setCreateMode(false);
                setShowPaticipantsBar(true);
              }}
            />
            Create Group
          </Text>
        }
        action={
          <div className="flex gap-m items-center">
            <Text variant={"caption_one"}></Text>
          </div>
        }
      />

      <Box className=" w-full ">
        <div className=" bg-info-200 w-[120px] h-[120px] flex justify-center items-center m-auto  rounded-full">
          {formik.values.image ? (
            <img
              src={formik.values.image}
              className=" rounded-full
                w-[120px] h-[120px]
                   object-cover"
            />
          ) : (
            <User className=" text-brand-800  w-[80px] h-[80px] " variant="Bold" />
          )}
        </div>
        <div className="mx-auto flex justify-center">
          <FileButton onChange={handleLogo} accept="image/png,image/jpeg">
            {(props) => (
              <Button
                {...props}
                loading={imageLoading}
                loaderProps={{ size: "xs" }}
                variant={"unstyled"}
              >
                Upload photo
              </Button>
            )}
          </FileButton>
        </div>
      </Box>

      <div className="px-m">
        <Text variant={"body_one"}>Name</Text>

        <Input
          variant="medium-s"
          className="rounded-4 w-full px-xl"
          inputClassName="w-full "
          placeholder="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          errorMessage={formik.errors.name}
          error={!!formik.errors.name}
        />

        <Text variant={"body_one"} className="mt-xl">
          Description
        </Text>

        <Input
          variant="medium-s"
          className="rounded-4 w-full px-xl"
          inputClassName="w-full "
          placeholder="Description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          errorMessage={formik.errors.description}
          error={!!formik.errors.description}
        />
      </div>

      <div className="flex justify-center gap-m p-l absolute left-0 bottom-0 right-0">
        <Button
          className="w-[180px]"
          variant="secondary"
          onClick={() => {
            setCreateMode(false);
            setShowPaticipantsBar(true);
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-[180px]"
          variant="primary"
          loading={loading}
          onClick={() => formik.handleSubmit()}
        >
          {selectedGroup?.id ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default CreateGroup;
