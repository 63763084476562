import { useInviteCodeQuery } from "@/hooks/use-invite-code-query";
import { useClipboard } from "@mantine/hooks";
import { Fragment, useMemo } from "react";
import { Text } from "@/components/ui/text";
import IconContainer from "@/components/ui/icon-container";
import { env } from "@/env";
import { Input } from "@/components/ui/input";
import { Check, Copy, Share2 } from "lucide-react";
import { useModal } from "@/stores/use-modal-store";
import { Loader } from "@/components/ui/loader";

export const ShareLink = () => {
  const { data: inviteCode, status } = useInviteCodeQuery();

  const { onOpen } = useModal();

  const { copied, copy } = useClipboard({ timeout: 500 });

  const link = useMemo(() => {
    return `${window.location.protocol}//${env.NEXT_PUBLIC_APP_DOMAIN}/invite/${inviteCode?.inviteCode}`;
  }, [inviteCode]);

  return (
    <Fragment>
      <div className="w-full">
        <div className="mb-1 flex items-center">
          <Text variant="body_one_strong">Invitation Link</Text>
        </div>
        <div className="flex flex-row w-full">
          <div className="flex gap-s-nudge w-full">
            <Input
              readOnly
              value={status === "pending" ? "" : link}
              rightSection={
                <Fragment>
                  {status === "pending" ? (
                    <Loader size="small" />
                  ) : (
                    <button onClick={() => copy(link)} className="flex items-center justify-center">
                      {copied ? (
                        <Check className="w-4 h-4 text-green-700" />
                      ) : (
                        <Copy className="w-4 h-4" />
                      )}
                    </button>
                  )}
                </Fragment>
              }
              fullWidth
              variant="medium"
              rightSectionClassName="py-2 px-2"
              onClick={() => copy(link)}
            />

            <div
              className="rounded-lg p-m ml-m border-1 flex items-center"
              onClick={() => onOpen("share", { share: { link, for: "Community" } })}
            >
              <Share2 className="h-[20px] w-[20px]" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
