import { useFormik } from "formik";
import { useParams } from "next/navigation";
import { Fragment, useState } from "react";
import clientApi from "@/api-client/client";
import { ApiResponse } from "@/types/api";
import { toast } from "sonner";
import Box from "@/components/ui/box";
import { isValidEmail } from "@key.ai/constants";
import { filter } from "lodash";
import { Button } from "@/components/ui/button";
import { Text } from "@/components/ui/text";
import EmailDropdown from "@/components/email-dropdown";
import { CloseCircle } from "iconsax-react";
import { extractEmail } from "extract-email-address";
import { InviteCode } from "@/types/entities";
import { Badge } from "@/components/ui/badge";

export const ManualUpload = () => {
  const { domain } = useParams<{ domain: string }>();
  const [search, setSearch] = useState("");

  const [invities, setInvities] = useState<InviteCode[]>([]);

  const formik = useFormik({
    initialValues: {
      emails: []
    },
    onSubmit: async (values, { setSubmitting, resetForm, setFieldError }) => {
      try {
        const _emails = [...extractEmail(search).map((e) => e.email), ...values.emails];
        const set = new Set(_emails);
        const emails = filter(Array.from(set), (value: string) => isValidEmail(value));
        setInvities([]);
        if (!emails.length)
          return setFieldError("emails", "Please enter valid email addresses for the invitation.");
        const { message, data } = await clientApi
          .post(`api/v1/${domain}/invite-code/by-email`, {
            json: { emails }
          })
          .json<ApiResponse<InviteCode[]>>();
        setInvities(data);
        toast.success(message);
        setSearch("");
        resetForm();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // const removeInvalidEmails = () => {
  //   formik.setFieldValue(
  //     "emails",
  //     filter(formik.values.emails, (value: string) => isValidEmail(value))
  //   );
  // };

  const removeEmail = (email: string) => {
    formik.setFieldValue(
      "emails",
      filter(formik.values.emails, (value: string) => value !== email)
    );
  };

  // const invalidEmails = useMemo(() => {
  //   return filter(formik.values.emails, (value: string) => !isValidEmail(value));
  // }, [formik.values]);

  return (
    <Fragment>
      <Box className="px-m-nudge py-1 field-border">
        <div className="flex items-center overflow-hidden">
          <div className="me-2 w-full overflow-hidden">
            <EmailDropdown
              onChange={(emails) => formik.setFieldValue("emails", emails)}
              selected={formik.values.emails}
              search={search}
              setSearch={setSearch}
            />
          </div>
          <Button
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            type="button"
            variant="primary"
            onClick={() => formik.handleSubmit()}
            className="ms-auto w-auto h-auto !rounded-4"
            layerClassName="py-[4px] h-auto px-s"
            loaderProps={{ size: "xs" }}
            size="sm"
          >
            <Text as="span" variant="caption_one" className="font-semibold">
              Send Invite
            </Text>
          </Button>
        </div>

        {/* {invalidEmails.length ? (
            <Fragment>
              <UnstyledButton size="xs" onClick={removeInvalidEmails}>
                <Text size="xs">
                  <Text c="red" component="span" className="mr-1">
                    {invalidEmails.length} errors.
                  </Text>
                  Remove all items with errors
                </Text>
              </UnstyledButton>
            </Fragment>
          ) : null} */}
      </Box>
      {formik.errors.emails && formik.touched.emails ? (
        <Text className="text-red-500 text-xs mt-2">{formik.errors.emails}</Text>
      ) : null}

      {invities.length ? (
        <Fragment>
          <div className="flex flex-wrap gap-s-nudge mt-s-nudge">
            <Text variant="caption_one" className="mt-1 w-full" as="div">
              You have already sent an invite to this person.
            </Text>
            {invities.map((invite) => (
              <Badge key={invite.id} className="bg-red-400">
                {invite.invitedTo}
              </Badge>
            ))}
          </div>
        </Fragment>
      ) : null}

      <div className="flex flex-wrap gap-s-nudge mt-s-nudge">
        {formik.values.emails.map((email, index) => (
          <div className="flex items-center px-m py-xs rounded-full gap-s bg-info-100" key={index}>
            <Text variant="body_two">{email}</Text>
            <button onClick={() => removeEmail(email)}>
              <CloseCircle className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </Fragment>
  );
};
