export enum EndPoints {
  ProfileImage = "profile",
  CoverImage = "cover",
  ThreadFile = "thread",
  CommentFile = "comment",
  ServerImage = "server",
  BannerImage = "banner",
  MessageFile = "message",
  PrivateFile = "private",
  PrivateCSV = "private/csv",
  Content = "content",
  Section = "section",
  eventBanner = "event_banners",
  calendarBanner = "calendar_banners"
}
