import React, { Fragment, useEffect, useState } from "react";
import Avatar from "@/components/ui/avatar";
import Box from "@/components/ui/box";
import { Button } from "@/components/ui/button";
import IconContainer from "@/components/ui/icon-container";
import Multiline from "@/components/ui/multiline";
import { Text } from "@/components/ui/text";
import app from "@/firebase/firebase";
import { useGoogleContacts } from "@/hooks/use-google-contacts";
import { getUserInitials } from "@/lib/utils";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { SearchNormal1 } from "iconsax-react";
import { toast } from "sonner";
import { Checkbox } from "@/components/ui/checkbox";
import { CONTACTS_SCOPE, isValidEmail } from "@key.ai/constants";
import useInView from "@/hooks/use-in-view";
import { Loader } from "@/components/ui/loader";
import { useSession } from "next-auth/react";
import baseClient from "@/api-client/base";
import { ScrollArea } from "@/components/ui/scroll-area";
import Image from "next/image";
import { Input } from "@/components/ui/input";
import { Check } from "lucide-react";
import NoDataView from "@/components/ui/no-data-view";

const auth = getAuth(app);

interface EmailEntry {
  email: string;
  role: string;
  selected: boolean;
}

interface GoogleUserProfile {
  id?: string;
  email?: string;
  verified_email?: boolean;
  name?: string;
  given_name?: string;
  family_name?: string;
  picture?: string;
  hd?: string;
}

const ContactList = ({
  accessToken,
  setEmails,
  emails,
  role,
  connectedAccount,
  handleDisconnect
}: {
  accessToken: string;
  setEmails: (emails: any) => void;
  emails: EmailEntry[];
  role: string;
  handleDisconnect: () => void;
  connectedAccount: GoogleUserProfile;
}) => {
  const [search, setSearch] = useState<string>("");
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const { data, fetchNextPage, isLoading, isFetchingNextPage }: any = useGoogleContacts({
    accessToken,
    query: search,
    selectAll
  });

  const [ref, isInView] = useInView({ threshold: 0 });

  useEffect(() => {
    if (isInView) {
      fetchNextPage();
    }
  }, [isInView]);

  const handleAdd = (email: any) => {
    if (email && isValidEmail(email)) {
      const existingEmail = emails.find((e: EmailEntry) => e.email === email);
      if (!existingEmail) {
        setEmails((prevState: EmailEntry[]) => {
          const newEmails = [
            ...prevState,
            {
              email,
              role: role,
              selected: true
            }
          ];
          updateSelectAllState(newEmails);
          return newEmails;
        });
      } else {
        setEmails((prevState: EmailEntry[]) => {
          const newEmails = prevState?.filter((e: EmailEntry) => e.email !== email);
          updateSelectAllState(newEmails);
          return newEmails;
        });
      }
    } else {
      toast.error("Invalid email");
    }
  };

  const contactList =
    data?.pages?.reduce((acc: any, current: any) => {
      const contacts = current?.connections || current?.results || [];
      return acc.concat(contacts);
    }, []) || [];

  const totalContacts = data?.pages?.[0]?.totalPeople || contactList?.length;

  useEffect(() => {
    if (selectAll && !isLoading && contactList?.length) {
      const allEmails = contactList.map((contact: any) => {
        const email = contact?.emailAddresses?.[0]?.value;
        return {
          email,
          role: role,
          selected: true
        };
      });

      const uniqueEmails = [...new Map(allEmails.map((item: any) => [item.email, item])).values()];

      setEmails(uniqueEmails);
    }
  }, [selectAll, isLoading]);

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      const allEmails = contactList.map((contact: any) => {
        const email = contact?.emailAddresses?.[0]?.value;
        return {
          email,
          role: role,
          selected: true
        };
      });

      const uniqueEmails = [...new Map(allEmails.map((item: any) => [item.email, item])).values()];

      setEmails(uniqueEmails);
    } else {
      setEmails([]);
    }
  };

  const updateSelectAllState = (newEmails: any) => {
    if (newEmails.length === totalContacts) {
      setSelectAll(true);
    } else if (newEmails.length === 0) {
      setSelectAll(false);
    } else {
      setSelectAll(false);
    }
  };

  return (
    <Fragment>
      {connectedAccount?.email ? (
        <div className="py-m px-l h-[70px] bg-zinc-100 flex items-start justify-between">
          <div>
            <Text variant={"subtitle_one"} className="font-semibold">
              Google Contacts
            </Text>
            <div className=" flex gap-s">
              <Text variant={"caption_one"} className="text-color-tertiary">
                {connectedAccount?.email}
              </Text>
              <Text
                variant={"caption_one"}
                className="text-brand-500 cursor-pointer"
                onClick={() => handleDisconnect()}
              >
                Disconnect
              </Text>
            </div>
          </div>
          <div className="flex items-center gap-s mr-xs">
            <Checkbox
              id="select-all"
              checked={selectAll}
              onCheckedChange={(e: boolean) => {
                setSelectAll(e);
                handleSelectAll(e);
              }}
            />
            <Text className="ml-1">Select All</Text>
          </div>
        </div>
      ) : null}
      <div className=" mt-m">
        <div className="px-l">
          <Input
            variant="medium-s"
            className="rounded-xs w-full px-xl"
            inputClassName="w-full"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            leftSectionBgClassName="bg-transparent !mr-2 items-center content-center p-none m-auto"
            leftSection={
              <SearchNormal1 size="24" className="text-color-secondary" variant="Outline" />
            }
          />
        </div>
        <ScrollArea
          className="max-h-[60vh] h-full flex flex-col mt-m"
          viewportClassName="max-h-[60vh]"
          type="always"
        >
          {isLoading ? (
            <div className="p-1 h-20 content-center">
              <Loader />
            </div>
          ) : contactList.length ? (
            contactList.map((_contact: any, index: number) => {
              const contact = _contact?.person || _contact;
              const email = contact?.emailAddresses?.[0]?.value;
              if (!email) return null;
              const user = {
                id: email,
                firstname: contact?.names?.[0]?.givenName,
                lastname: contact?.names?.[0]?.familyName,
                email,
                username: email,
                profile_photo: contact?.photos?.[0]?.url
              };
              const isSelected = !!emails.find((e: any) => e.email === email);

              const initals = getUserInitials(user);

              return (
                <Fragment key={index}>
                  <div className={`px-l`} onClick={() => handleAdd(email)}>
                    <Multiline
                      className="!px-0"
                      icon={
                        <div className="flex gap-m items-center">
                          <Avatar
                            text={initals}
                            imgSrc={contact?.photos?.[0]?.url}
                            size={56}
                            activeRing={true}
                            variant={contact?.photos?.[0]?.url ? "image" : "initials"}
                            present={false}
                            presence={false}
                          />
                        </div>
                      }
                      text1={email}
                      action={isSelected && <Check />}
                      titleTextSize="medium"
                      text1Size="small"
                    />
                  </div>
                  <div className="border-b border-gray-200 w-full"></div>
                </Fragment>
              );
            })
          ) : (
            <div className="content-center h-full mx-auto text-center">
              <NoDataView title={`No contacts found.`} imageKey={"guest"} />
            </div>
          )}
          {isFetchingNextPage ? (
            <div className="p-1 h-20 content-center">
              <Loader />
            </div>
          ) : null}
          {contactList.length ? <div ref={ref} className="h-2 w-full" /> : null}
        </ScrollArea>
      </div>
    </Fragment>
  );
};

const GoogleContacts = ({
  emails,
  setEmails,
  role
}: {
  emails: [];
  setEmails: (emails: any) => void;
  role: string;
}) => {
  const [connect, setConnect] = useState<boolean>(false);
  const [connectedAccount, setConnectedAccount] = useState<GoogleUserProfile>({});

  const { data: session } = useSession();

  const [accessToken, setAccessToken] = useState<string>(
    sessionStorage.getItem(`google_auth_token_${session?.user?.id}`) || ""
  );

  const fetchConnectedAccount = async () => {
    try {
      const response = await baseClient(`https://www.googleapis.com/oauth2/v1/userinfo`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).json<GoogleUserProfile>();
      setConnectedAccount(response);
    } catch (error) {}
  };

  useEffect(() => {
    if (accessToken) {
      fetchConnectedAccount();
    }
  }, [accessToken]);

  const handleGoogleAccountConnect = async () => {
    setConnect(true);
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope(CONTACTS_SCOPE);
      const result = await signInWithPopup(auth, provider);

      const credential = GoogleAuthProvider.credentialFromResult(result);

      if (credential?.accessToken) {
        sessionStorage.setItem(`google_auth_token_${session?.user?.id}`, credential.accessToken);
        setAccessToken(credential?.accessToken);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setConnect(false);
    }
  };

  const handleDisconnect = async () => {
    setAccessToken("");
    setConnectedAccount({});
    sessionStorage.removeItem(`google_auth_token_${session?.user?.id}`);
  };

  return (
    <div>
      <Box className="p-0 mt-xl">
        {accessToken ? (
          <ContactList
            role={role}
            accessToken={accessToken}
            setEmails={setEmails}
            emails={emails}
            connectedAccount={connectedAccount}
            handleDisconnect={handleDisconnect}
          />
        ) : (
          <Box className="bg-none m-0 mx-l border-zinc-100 pt-0 " withBorder>
            <Multiline
              icon={
                <IconContainer
                  size="default"
                  as="div"
                  className="border-none"
                  icon={
                    <Image
                      src="/static/images/social/google.png"
                      width={26}
                      height={26}
                      alt="Google"
                    />
                  }
                />
              }
              titleText={<Text variant={"body_two"}>Connect Google Account</Text>}
              text1={"Please link with your google account"}
              titleTextSize={"medium"}
              text1Size={"small"}
              className=" rounded-medium pl-m mt-s"
            />
            <Button
              className="adaptive-width ml-[72px] mt-m"
              variant="primary"
              onClick={handleGoogleAccountConnect}
              loading={connect}
              disabled={connect}
              loaderProps={{ size: "xs" }}
            >
              Connect
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default GoogleContacts;
