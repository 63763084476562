import React, { useCallback, useState } from "react";
import { useCSVReader } from "react-papaparse";
import Box from "@/components/ui/box";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import Multiline from "@/components/ui/multiline";
import SectionHeader from "@/components/ui/section-header";
import { Text } from "@/components/ui/text";
import {
  CloseCircle,
  DocumentCode,
  DocumentDownload,
  DocumentUpload,
  SearchNormal1
} from "iconsax-react";
import Avatar from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import IconContainer from "@/components/ui/icon-container";
import { EMAIL_REGEX } from "@key.ai/constants";
import { toast } from "sonner";

const CSVUpload = ({ emails, setEmails, role, setGuestPreview }: any) => {
  const { CSVReader } = useCSVReader();

  const sampleFile = useCallback(() => {
    const data = [["Email"], ["user1@example.com"], ["user2@example.com"]];
    const csvContent = data.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "bulk_user_invite_sample.csv";
    link.style.display = "none";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }, []);

  const handleReadData = ({ data }: any) => {
    if ((data[0][0] || "").toLowerCase().includes("email")) {
      const emails = data
        .map((col: string[]) => col[0])
        .filter((email: string) => EMAIL_REGEX.test(email.trim()))
        .map((email: string) => {
          return { email: email.trim(), selected: true, role: role };
        });
      if (emails.length) {
        setEmails(emails);
        toast.success("CSV Imported");
        setGuestPreview(true);
      } else {
        toast.error("Please upload a file containing valid email addresses.");
      }
    } else {
      toast.error("Please upload a valid file, refer to the sample file for guidance.");
    }
  };

  return (
    <div>
      <Box className=" mt-xl">
        <CSVReader
          onUploadAccepted={(results: any) => {
            handleReadData(results);
          }}
        >
          {({ getRootProps, acceptedFile }: any) => (
            <div {...getRootProps()}>
              <div className="flex justify-center items-center border-dashed border h-[200px] rounded-4 mx-l">
                <div className="text-center">
                  <IconContainer
                    className="mx-auto"
                    size="default"
                    as="div"
                    icon={<DocumentUpload />}
                  />
                  <Text variant={"body_two_strong"} className="mt-l">
                    Import CSV File
                  </Text>
                  <Text className=" text-color-tertiary" variant={"caption_one"}>
                    Drop here or click here to choose
                  </Text>
                </div>
              </div>
            </div>
          )}
        </CSVReader>

        <div className="flex gap-s items-center justify-center mt-m" onClick={sampleFile}>
          <DocumentDownload className="text-color-tertiary" size="16" />
          <Text className="text-color-tertiary" variant={"body_two"}>
            Download Sample CSV
          </Text>
        </div>
      </Box>
    </div>
  );
};

export default CSVUpload;
